<template>
  <el-dialog
          :title="'资源详情'"
          :close-on-click-modal="false"
          :visible.sync="visible">
    <p>ID: {{dataInfo.id}}</p>
    <p>资源名称: {{dataInfo.name}}</p>
    <p>申请人: {{dataInfo.applicationPerson}}</p>
    <p>联系方式: {{dataInfo.iphone}}</p>
    <p>申请目的: {{dataInfo.purpose}}</p>
    <p>提供数量: {{dataInfo.num}}</p>
    <p>种质形态: {{dataInfo.germplasmMorphology}}</p>
    <template >
      <p>
        <span v-if="dataInfo.status===0">申请状态：未审核</span>
        <span v-else-if="dataInfo.status===1">申请状态：已通过</span>
        <span v-else>申请状态：未通过</span>
      </p>
    </template>
    <p>申请时间: 2021-5-21</p>
    <span slot="footer" class="dialog-footer">

      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="visible = false">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    data () {
      return {
        visible:false,
        dataForm: {
          id: 0,
          serveObjectName: '',
          sid: '',
          oid: '',
          serveNum: '',
          imgs: '',
          address: '',
          serveDate: '',
          uid: '',
          createDete: ''
        },
      }
    },
    props: {
      // id:{
      //   type: String,
      //   default:''
      // },
      dataInfo: {
        type: Object,
        defaults: {}
      }
    },
    methods: {
      init(){
        this.visible = true
      },
      // 表单提交
      dataFormSubmit () {

      }
    }
  }
</script>
