<template>
  <div>
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">

      <el-form-item>
        <el-radio-group v-model="selectType" style="margin-bottom: 30px;" @change="getDataList">
          <el-radio-button label='1' >申请审核</el-radio-button>
          <el-radio-button label='2' >争议审核</el-radio-button>
          <el-radio-button label='3' >数据审核</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-right: 0">
        <el-input v-model="dataForm.key" placeholder="资源名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      style="width: 100%">
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        width="80"
        label="ID">
      </el-table-column>
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="资源名称">
      </el-table-column>
      <el-table-column
        prop="applicationPerson"
        header-align="center"
        align="center"
        width="180"
        label="申请人">
      </el-table-column>
      <el-table-column
        prop="iphone"
        header-align="center"
        align="center"
        width="150"
        :show-overflow-tooltip="true"
        label="联系方式">
      </el-table-column>
      <el-table-column
        prop="purpose"
        header-align="center"
        align="center"
        :show-overflow-tooltip="true"
        label="申请目的">
      </el-table-column>
      <el-table-column
        prop="num"
        header-align="center"
        align="center"
        width="80"
        label="提供数量">
      </el-table-column>
      <el-table-column
        prop="germplasmMorphology"
        header-align="center"
        align="center"
        width="150"
        label="种质形态">
      </el-table-column>
      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        width="180"
        label="状态">
        <template slot-scope="scope">
          <p>
            <span v-if="scope.row.status===0">未审核</span>
            <span v-else-if="scope.row.status===1">已通过</span>
            <span v-else>未通过</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column
              fixed="right"
              header-align="center"
              align="center"
              width="280"
              label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="getDetailInfo(scope.row.id,scope.row)">查看详情</el-button>
          <el-button type="text" size="small" v-if="scope.row.status===0" @click="setStatus(scope.row.id, 1)">通过</el-button>
          <el-button type="text" size="small" v-if="scope.row.status===0" @click="setStatus(scope.row.id, 2)">未通过</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <resources-detail v-if="detailVisible" :data-info="selectDataInfo"  ref="ResourcesDetail"></resources-detail>
  </div>
</template>

<script>

  import ResourcesDetail from './resources-detail'
  export default {
    data () {
      return {
        dataForm: {
          key: ''
        },
        selectType: '1',
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        detailVisible:false,
        dataListLoading: false,
        selectionDataList: [],
        selectDataInfo: {},
        allData1:[
          {'id':1,
            'name':"巴西橡胶1",
            'applicationPerson':"王麻子",
            'iphone':"18638567111",
            'purpose':"培育",
            'num':"100",
            'germplasmMorphology':"组培",
            'status':0},
          {'id':2,
            'name':"巴西橡胶2",
            'applicationPerson':"李四",
            'iphone':"18638567111",
            'purpose':"培育",
            'num':"600",
            'germplasmMorphology':"组培",
            'status':0},
          {'id':2,
            'name':"巴西橡胶3",
            'applicationPerson':"张三",
            'iphone':"6875123",
            'purpose':"培育",
            'num':"456",
            'germplasmMorphology':"组培",
            'status':1},
        ],
        allData2:[
          {'id':1,
            'name':"巴西橡胶1",
            'applicationPerson':"王麻子",
            'iphone':"186****111",
            'purpose':"归属问题",
            'num':"100",
            'germplasmMorphology':"组培",
            'status':0},
          {'id':2,
            'name':"巴西橡胶2",
            'applicationPerson':"小五",
            'iphone':"186****211",
            'purpose':"培育",
            'num':"600",
            'germplasmMorphology':"组培",
            'status':0},
        ],
        allData3:[
          {'id':1,
            'name':"巴西橡胶1",
            'applicationPerson':"王麻子",
            'iphone':"18638567111",
            'purpose':"数据错误",
            'num':"100",
            'germplasmMorphology':"组培",
            'status':0},
          {'id':2,
            'name':"巴西橡胶2",
            'applicationPerson':"李四",
            'iphone':"18638567111",
            'purpose':"数据错误",
            'num':"600",
            'germplasmMorphology':"组培",
            'status':0},
          {'id':2,
            'name':"巴西橡胶3",
            'applicationPerson':"张三",
            'iphone':"6875123",
            'purpose':"数据错误",
            'num':"456",
            'germplasmMorphology':"组培",
            'status':1},
        ],
      }
    },
    components: {
      ResourcesDetail,
    },
    created () {
      this.getDataList()
    },
    methods: {
      // 获取数据列表
      getDataList () {
        let allData = []
        if(this.selectType === '1'){
          allData = this.allData1
        }
        else if(this.selectType === '2'){
          allData = this.allData2
        }
        else{
          allData = this.allData3
        }
        this.dataListLoading = false
        // 0：未审核；1已通过；2未通过
        if(this.dataForm.key === ''){
          this.dataList = allData
        }
        else{
          this.dataList = []
          for(let i = 0; i < allData.length;i++){
            if(allData[i]['name'] === this.dataForm.key){
              this.dataList.push(allData[i])
            }
          }
        }
        // this.$http({
        //   url: this.$http.adornUrl('/sys/log/list'),
        //   method: 'get',
        //   params: this.$http.adornParams({
        //     'page': this.pageIndex,
        //     'limit': this.pageSize,
        //     'key': this.dataForm.key
        //   })
        // }).then(({data}) => {
        //   if (data && data.code === 0) {
        //     this.dataList = data.page.list
        //     console.log(this.dataList)
        //     this.totalPage = data.page.totalCount
        //   } else {
        //     this.dataList = []
        //     this.totalPage = 0
        //   }
        //   this.dataListLoading = false
        // })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      getDetailInfo(id, detailInfo){
        this.detailVisible = true
        this.selectDataInfo = detailInfo
        this.$nextTick(() => {
          this.$refs.ResourcesDetail.init()
        })
      },
      setStatus(id,s){
        for(let i =0; i < this.dataList.length; i++){
          if(this.dataList[i]['id'] === id){
            this.dataList[i]['status'] = s
            this.$message.success("审核成功")
          }
        }
      }
    }
  }
</script>
